<template>
	<div>
		<v-row class="d-flex justify-end mx-4 mt-2">
			<span class="selector" @click="toggleFilter">
				<v-icon x-small>{{ filterIcon }}</v-icon>
				{{ $t('gisweb.toolbar.filters') }}
			</span>
		</v-row>
		<v-row class="mt-0" v-show="visibleFilters">
			<v-col cols="2" v-if="isVisibleTimeFilter">
				<pui-date-field
					:id="`dateini-giswebtoolbar`"
					modelName="dateini"
					v-model="filterDateIni"
					:label="$t('gisweb.toolbar.dateini')"
					toplabel
					:max="isTabKeyHistorical ? filterDateEnd || new Date().setDate(new Date().getDate()) : filterDateEnd || null"
				></pui-date-field>
			</v-col>
			<v-col cols="2" v-if="isVisibleTimeFilter">
				<pui-date-field
					:id="`dateend-giswebtoolbar`"
					modelName="filterDateEnd"
					v-model="filterDateEnd"
					:label="$t('gisweb.toolbar.dateend')"
					toplabel
					:min="filterDateIni || null"
					:max="isTabKeyHistorical ? new Date().setDate(new Date().getDate()) : null"
				></pui-date-field>
			</v-col>

			<v-col cols="3">
				<pui-select
					:id="`shiptype-${tabmodel}`"
					:attach="`shiptype-${tabmodel}`"
					:label="$t('gisweb.toolbar.shiptype')"
					toplabel
					clearable
					v-model="filterShipType"
					modelName="shiptypensw"
					itemValue="code"
					itemText="description"
					:order="{ name: 'asc' }"
					:multiple="true"
				></pui-select>
			</v-col>
			<v-col cols="3">
				<pui-select
					:id="`berth-${tabmodel}`"
					:attach="`berth-${tabmodel}`"
					:label="$t('gisweb.toolbar.berth')"
					toplabel
					clearable
					v-model="filterBerth"
					modelName="berth"
					itemValue="id"
					:itemText="(item) => (item.berthsubcode ? `${item.name} - ${item.berthsubcode}` : item.name)"
					:order="{ name: 'asc' }"
					:multiple="true"
					:fixedFilter="filterByPortAuthorityUser"
				></pui-select>
			</v-col>
			<v-col cols="2">
				<pui-text-field
					:id="`text-giswebtoolbar`"
					v-model="filterText"
					modelName="text"
					:label="$t('gisweb.toolbar.text')"
					toplabel
					maxlength="100"
				></pui-text-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { TAB_KEY_HISTORICAL } from '../utils/tabKeysConstants.js';
export default {
	name: 'GiswebToolbar',
	data() {
		return {
			visibleFilters: true
		};
	},
	props: {
		isVisibleTimeFilter: {
			type: Boolean,
			default: true
		},
		tabKey: {
			type: String,
			required: false
		}
	},
	computed: {
		tabmodel: {
			get() {
				return this.$store.getters.getTabModel;
			}
		},
		filterDateIni: {
			get() {
				return this.$store.getters.getFilter(this.tabKey).dateini;
			},
			set(value) {
				this.$store.commit('setFilterModel', { key: this.tabKey, property: 'dateini', value: value });
			}
		},
		filterDateEnd: {
			get() {
				return this.$store.getters.getFilter(this.tabKey).dateend;
			},
			set(value) {
				this.$store.commit('setFilterModel', { key: this.tabKey, property: 'dateend', value: value });
			}
		},
		filterShipType: {
			get() {
				return this.$store.getters.getFilter(this.tabKey).shiptype;
			},
			set(value) {
				const currentValue = this.$store.getters.getFilter(this.tabKey).shiptype;

				// Compara los arrays comprobando su longitud y contenido
				const arraysAreEqual = currentValue.length === value.length && currentValue.every((item, index) => item === value[index]);

				if (!arraysAreEqual) {
					// Solo comitea si los arrays son diferentes
					this.$store.commit('setFilterModel', { key: this.tabKey, property: 'shiptype', value: value });
				}
			}
		},
		filterBerth: {
			get() {
				console.log(this.$store.getters.getFilter(this.tabKey).berth);
				return this.$store.getters.getFilter(this.tabKey).berth;
			},
			set(value) {
				const currentValue = this.$store.getters.getFilter(this.tabKey).berth;

				// Compara los arrays comprobando su longitud y contenido
				const arraysAreEqual = currentValue.length === value.length && currentValue.every((item, index) => item === value[index]);

				if (!arraysAreEqual) {
					// Solo comitea si los arrays son diferentes
					this.$store.commit('setFilterModel', { key: this.tabKey, property: 'berth', value: value });
				}
			}
		},
		filterText: {
			get() {
				return this.$store.getters.getFilter(this.tabKey).text;
			},
			set(value) {
				this.$store.commit('setFilterModel', { key: this.tabKey, property: 'text', value: value });
			}
		},
		filterByPortAuthorityUser() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'port_authority', op: 'eq', data: this.$store.getters.getPortAuthorityId }]
			};
		},
		isTabKeyHistorical() {
			return this.tabKey === TAB_KEY_HISTORICAL;
		},
		filterIcon() {
			return this.visibleFilters ? 'fa-kit fa-solid-up' : 'fa-kit fa-solid-down';
		}
	},
	methods: {
		toggleFilter() {
			this.visibleFilters = !this.visibleFilters;
			this.$emit('update:togglefilters', this.visibleFilters);
		}
	}
};
</script>

<style scoped>
.row {
	padding-bottom: 0px;
}
.selector,
.selector .v-icon {
	color: var(--primary);
}
.selector {
	font-size: 0.9rem;
	line-height: 1.33;
	font-weight: 900;
}
.selector:hover {
	cursor: pointer;
}
</style>
