<template>
	<div class="pui-toolbar__multiBtn elevation-0 mb-2">
		<div ref="btn">
			<v-btn color="primary" class="elevation-0 pui-toolbar__btn pui-toolbar__superMiniBtn" @click.native.stop="states.active = !states.active">
				<v-icon class="pr-3" small>fa fa-file-export</v-icon>
				<v-icon v-if="!states.active" small>fa fa-angle-down</v-icon>
				<v-icon v-else small>fa fa-angle-up</v-icon>
			</v-btn>
		</div>
		<div v-show="states.active" class="pui-dialog--export elevation-5 pl-0 pr-0" :ref="'dialog'" @click.stop>
			<v-layout wrap class="mb-1">
				<v-list>
					<v-list-item
						v-for="format in formats"
						:key="format.extension"
						@click="exportData(format)"
						tabindex="0"
						@keydown.native.enter="exportData(format)"
					>
						<v-list-item-action>
							<v-icon style="font-size: 16px">{{ `fa fa-${format.icon}` }}</v-icon>
						</v-list-item-action>

						<v-list-item-content>
							<v-list-item-title>{{ `${$t('puidatatables.export_to')} ${format.name}` }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-layout>
		</div>
	</div>
</template>

<script>
import PuiToolbarBtnMixin from '@Pui9Datatables/src/mixins/PuiToolbarBtnMixin';

export default {
	name: 'GridExportBtn',
	mixins: [PuiToolbarBtnMixin],
	props: {
		modelName: {
			type: String
		},
		headers: {
			type: Array,
			required: true
		},
		items: {
			type: Array
		},
		printMap: {
			type: Boolean,
			required: true
		},
		mapManager: {
			type: Object,
			required: false
		},
		dynamicMapRef: {
			type: String,
			required: true
		}
	},
	data() {
		const formats = {
			csv: {
				name: 'Excel',
				type: 'direct',
				extension: '.csv',
				icon: 'file-csv'
			},
			pdf: {
				name: 'PDF',
				type: 'direct',
				extension: '.pdf',
				icon: 'file-pdf'
			}
		};
		/* if (this.$store.getters.hasFunctionality('READ_GISWEB_DIARY')) {
			formats.pdf = {
				name: 'PDF',
				type: 'direct',
				extension: '.pdf',
				icon: 'file-pdf'
			};
		} */
		return {
			idTool: 'export',
			panel: {
				position: {}
			},
			formats
		};
	},
	methods: {
		exportData(format) {
			this.$store.state.global.puiloader.message = this.$t('puidatatables.downloading');
			this.$store.state.global.puiloader.show = true;

			const currentColumns = this.headers.map((column, index) => {
				return {
					order: index,
					title: this.$t(column.text),
					name: column.value,
					...(column.value === 'eta' || column.value === 'etd' ? { dateformat: 'dd/MM/yyyy HH:mm' } : {})
				};
			});

			const currentItems = this.items.map((item) =>
				this.headers.reduce((obj, key) => {
					if (item.hasOwnProperty(key.value)) {
						obj[key.value] = item[key.value];
					}
					return obj;
				}, {})
			);

			const exportData = {
				exportTitle: this.$t('gisweb.' + this.modelName.split('stop')[1] + '.title'),
				exportType: format.extension.replace('.', ''),
				exportColumns: currentColumns,
				exportRows: currentItems
			};
			const sendRequest = (data) => {
				this.$puiRequests.downloadFileRequest(
					'post',
					`/vstopoperations/exportGisweb`,
					data,
					(response) => {
						if (response.data && response.headers) {
							const link = document.createElement('a');
							link.href = window.URL.createObjectURL(new window.Blob([response.data]));
							link.download = this.$puiUtils.getFilenameFromHeaders(response.headers);
							link.click();
						} else {
							this.$puiNotify.error(this.$t('pui9.error.puigridexportbtn'), '', 2000, true);
						}
						this.$store.state.global.puiloader.message = null;
						this.$store.state.global.puiloader.show = false;
					},
					() => {
						this.$puiNotify.error(this.$t('pui9.error.puigridexportbtn'), '', 2000, true);
						this.$store.state.global.puiloader.message = null;
						this.$store.state.global.puiloader.show = false;
					}
				);
			};
			// Exportar mapa
			if (this.printMap && exportData.exportType === 'pdf') {
				const mapContainer = document.getElementById(this.dynamicMapRef);
				this.mapManager
					.printMapPdf(mapContainer)
					.then((imgData) => {
						exportData.exportMap = imgData;
						sendRequest(exportData);
					})
					.catch((error) => {
						console.error(error);
					});
			} else {
				sendRequest(exportData);
			}
		}
	}
};
</script>

<style lang="postcss">
.pui-dialog--export {
	border-radius: 5px;
	position: absolute;
	z-index: 999;
	background: white !important;
}
</style>
