import { util } from 'jointjs/src/core.mjs';

export const Inertia = function(onInertiaMove, opt) {

    // NOTE: there is one instance of this object per each PaperScroller which has inertia enabled
    // - meaning that we have to reinitialize for each dragStart!

    this.options = util.assign({
        friction: 0.92,
    }, opt);

    // callback function - to receive the calculated deltas each frame and do something with them
    this.onInertiaMove = onInertiaMove;

    this._isDragging = false;

    this.initialize(); // not crucial (just to make Inertia object correctly communicate that nothing is happening)
}

Inertia.prototype.initialize = function() {

    this._dragLastX = 0;
    this._dragLastY = 0;

    this._dragDeltaX = 0;
    this._dragDeltaY = 0;

    this._dragLastDeltaX = 0;
    this._dragLastDeltaY = 0;

    this._velocityX = 0;
    this._velocityY = 0;

    this._requestAnimationFrameId = -1;
}

Inertia.prototype.approxZero = function(number) {

    return Math.abs(number) < 0.5;
}

Inertia.prototype.updateVelocity = function() {

    // save the current value of these variables
    const { _velocityX, _velocityY, _isDragging, options, onInertiaMove } = this;

    if (!_isDragging && this.approxZero(_velocityX) && this.approxZero(_velocityY)) {
        // we are in inertia phase, but we are moving so little in both dimensions that we should stop
        this.initialize(); // not crucial (just to make Inertia object correctly communicate that nothing is happening)
        return;
    }
    // else: we should trigger the animation

    // sign up to trigger this function again in the next frame
    this._requestAnimationFrameId = util.nextFrame(this.updateVelocity.bind(this));

    // calculate what happens in this frame
    if (_isDragging) {
        // while dragging, build up velocity based on incremental increases in each coordinate
        // = recording phase
        // (we use `this.` variables here so that they will get updated during dragMove)

        // save previous delta
        this._dragLastDeltaX = this._dragDeltaX;
        this._dragLastDeltaY = this._dragDeltaY;

        // save current delta
        this._dragDeltaX = this._dragLastX;
        this._dragDeltaY = this._dragLastY;

        // velocity is calculated as difference between current and previous deltas
        this._velocityX = (this._dragDeltaX - this._dragLastDeltaX);
        this._velocityY = (this._dragDeltaY - this._dragLastDeltaY);

    } else {
        // after dragEnd, each animation frame slowly decrease the velocity based on `friction`
        // = inertia phase

        // save deltas based on current velocity
        const deltaX = _velocityX;
        const deltaY = _velocityY;

        // calculate velocity for next frame
        const { friction } = options;
        this._velocityX *= friction;
        this._velocityY *= friction;

        // pass current deltas to provided callback function
        if (typeof onInertiaMove === 'function') {
            onInertiaMove(deltaX, deltaY);
        }
    }
}

Inertia.prototype.handleDragStart = function(event) {

    this._isDragging = true;

    // cancel ongoing inertia animation (if any), and refresh all variables
    util.cancelFrame(this._requestAnimationFrameId);
    this.initialize(); // crucial for functionality!

    // make sure to start with 0 velocity (calculated as `_dragLast` - `_dragDelta`)
    // - so that inertia is not triggered for taps with no movement (i.e. dragStart immediately followed by dragEnd)
    this._dragLastX = event.clientX;
    this._dragLastY = event.clientY;

    this._dragDeltaX = event.clientX;
    this._dragDeltaY = event.clientY;

    this.updateVelocity();
}

Inertia.prototype.handleDragMove = function(event) {

    this._dragLastX = event.clientX;
    this._dragLastY = event.clientY;
}

Inertia.prototype.handleDragEnd = function(_event) {

    this._isDragging = false;

    // NOTE: we should NOT call `initialize()` here
    // - because the inertia animation may continue beyond the drag interaction (obviously)
}
