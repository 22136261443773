<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<v-row dense>
						<v-col cols="4">
							<pui-text-field
								:id="`code-shiptypeais`"
								v-model="model.code"
								:label="$t('form.shiptypeais.code')"
								:disabled="formDisabled || disableForm"
								required
								toplabel
								maxlength="2"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="4">
							<pui-text-field
								:id="`description-shiptypeais`"
								v-model="model.description"
								:label="$t('form.shiptypeais.description')"
								:disabled="formDisabled || disableForm"
								required
								toplabel
								maxlength="100"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="4">
							<pui-select
								id="shiptypepa-shiptypeais"
								attach="shiptypepa-shiptypeais"
								:label="$t('form.shiptypeais.shiptypepadescription')"
								toplabel
								clearable
								v-model="model"
								modelName="shiptypepa"
								:disabled="formDisabled"
								:modelFormMapping="{ code: 'shiptypepa' }"
								:itemsToSelect="[{ code: this.model.shiptypepa }]"
								itemValue="code"
								itemText="description"
								:order="{ description: 'asc' }"
								:fixedFilter="filterByPortAuthorityUser"
							></pui-select>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="customSave" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'shiptypeais-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	data() {
		return {
			modelName: 'shiptypeais',
			disableForm: false
		};
	},
	methods: {
		afterGetData() {
			this.model.portauthorityid = this.$store.getters.getPortAuthorityId;
			if (!this.isCreatingElement) {
				this.disableForm = true;
				//Get ship type PA data
				const opts = {
					model: 'shiptypeais',
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								field: 'code',
								op: 'eq',
								data: this.model.code
							}
						]
					}
				};
				this.$puiRequests.postRequest('/puisearch', opts, (response) => {
					if (response.data && response.data.data[0]) {
						//Fill data
						this.model.oldshiptypepa = response.data.data[0].shiptypepa;
						this.model.shiptypepa = response.data.data[0].shiptypepa;
					}
				});
			}
		},
		customSave() {
			if (this.isCreatingElement) {
				this.$puiRequests.postRequest(
					'/shiptypeais/fullinsert',
					this.model,
					(response) => {
						this.saving = false;
						this.$puiEvents.$emit(`onPui-insertRow-dataTable-${this.modelDependency.name}`, response.data);
						this.$puiNotify.success(this.$t('pui9.save.success'));
						this.afterSave && this.afterSave(response.data);
						this.back();
						this.resetAfterSaveActions();
					},
					(error) => {
						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
						this.resetAfterSaveActions();
						this.saving = false;
						this.onSaveError && this.onSaveError();
						this.onError(error);
					}
				);
			} else {
				this.$puiRequests.postRequest(
					'/shiptypeais/fullupdate',
					this.model,
					(response) => {
						this.saving = false;
						this.$puiEvents.$emit(`onPui-insertRow-dataTable-${this.modelDependency.name}`, response.data);
						this.$puiNotify.success(this.$t('pui9.save.success'));
						this.afterSave && this.afterSave(response.data);
						this.back();
						this.resetAfterSaveActions();
					},
					(error) => {
						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
						this.resetAfterSaveActions();
						this.saving = false;
						this.onSaveError && this.onSaveError();
						this.onError(error);
					}
				);
			}
		}
	},
	computed: {
		filterByPortAuthorityUser() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'port_authority_id', op: 'eq', data: this.$store.getters.getPortAuthorityId }]
			};
		}
	},
	created() {}
};
</script>
