<template>
	<div>
		<v-row class="mt-2">
			<v-col cols="12" class="pa-6 pt-1 pb-0">
				<pui-switch
					:label="$t('operations.track.checkboxTrack')"
					v-model="showTrackMenu"
					color="var(--primary)"
					class="my-auto my-0 py-0 pb-5"
				></pui-switch>
			</v-col>
		</v-row>
		<!-- class="my-auto my-0 py-0 mt-2" -->
		<div class="track" v-if="showTrackMenu">
			<v-row class="mt-2">
				<v-col cols="1" class="ml-6">
					<v-icon>fa-kit fa-line-area-measurement</v-icon>
				</v-col>
				<v-col cols="9" class="ml-6">
					<div class="text-h6 font-weight-black">{{ $t('operations.track.trackTitle') }}</div>
				</v-col>
				<v-col cols="12" class="pa-6 pt-1 pb-0">
					<pui-date-field
						v-model="datefrom"
						:id="`track-datefrom`"
						:label="$t('operations.track.dateFrom')"
						toplabel
						required
						time
						class="font-weight: bold;"
						:max="dateto"
						@input="validateDateFrom"
					></pui-date-field>
				</v-col>
				<v-col cols="12" class="pa-6 pt-1 pb-0">
					<pui-date-field
						v-model="dateto"
						:id="`track-dateto`"
						:label="$t('operations.track.dateTo')"
						toplabel
						required
						time
						:min="datefrom"
						@input="validateDateTo"
					></pui-date-field>
				</v-col>
				<v-col cols="12" class="pa-6 pt-1 pb-0">
					<p>
						<strong>{{ $t('operations.track.visualizationtitle') }}</strong>
					</p>
				</v-col>
				<v-col cols="12" class="pa-6 pt-1 pb-0">
					<pui-checkbox :id="`visPosAis`" v-model="visposais" :label="$t('operations.track.visAis')"></pui-checkbox>
					<pui-checkbox :id="`visTrack`" v-model="vistrack" :label="$t('operations.track.visTrack')"></pui-checkbox>
					<pui-checkbox :id="`visWayEvents`" v-model="viswayevents" :label="$t('operations.track.visEvents')"></pui-checkbox>
				</v-col>
				<v-col v-if="eventItems.length > 0" cols="12" class="pa-6 pt-1 pb-0">
					<p class="mb-0">
						<strong>{{ $t('operations.track.eventstitle') }}</strong>
					</p>
					<v-carousel
						class="mt-0"
						color="#ddecf6"
						hide-delimiter-background
						v-model="currentEvent"
						:style="{ height: this.carouselHeight }"
					>
						<v-carousel-item v-for="(element, index) in eventItems" :key="index" class="custom-carousel-item" style="height: auto">
							<v-sheet color="#ddecf6" tile>
								<v-row class="fill-height" align="center" justify="center">
									<strong v-if="element.geomdesc != ''" style="color: var(--primarycolor); font-size: 14px; padding-top: 20px"
										>{{ element.index }} - {{ element.text }} ({{ element.geomdesc }})</strong
									>
									<strong v-else style="color: var(--primarycolor); font-size: 14px; padding-top: 20px"
										>{{ element.index }} - {{ element.text }}</strong
									>
								</v-row>
							</v-sheet>
						</v-carousel-item>
						<template v-slot:prev>
							<v-btn @click="previousSlide" icon small style="color: black; background-color: #ddecf6"
								><v-icon style="font-size: 14px; font-weight: bold">far fa-chevron-left</v-icon></v-btn
							>
						</template>
						<template v-slot:next>
							<v-btn @click="nextSlide" icon small style="color: black; background-color: #ddecf6"
								><v-icon style="font-size: 14px; font-weight: bold">far fa-chevron-right</v-icon></v-btn
							>
						</template>
					</v-carousel>
				</v-col>
				<v-col cols="12">
					<v-row class="d-flex justify-space-around mt-1 mb-1">
						<v-btn color="primary" @click="updateBtn">{{ $t('operations.track.update') }}</v-btn>
					</v-row>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
/** Constants */
import { AIS_TRACK_EVENT_POINT_LAYER_ID } from '@/components/operations/map/constants/layers';

import ol2map from '@/components/operations/map/sections/map/subcomponents/ol2map';

export default {
	props: ['panelOptions'],
	components: {},

	data() {
		return {
			zoomToCoordinates: 16,
			currentEvent: 0,
			diffTime: 2 * 24 * 60 * 60 * 1000, // Tiempo de diferencia entre fechas en milisegundos
			eventItems: [],
			visposais: true,
			vistrack: true,
			viswayevents: true,
			datefrom: new Date(new Date().getTime() - 2 * 24 * 60 * 60 * 1000).toISOString(),
			dateto: new Date().toISOString(),
			carouselHeight: '0px'
		};
	},

	mounted() {
		this.setPanelOptionsData();
		this.$puiEvents.$on('track:updateeventlegend', this.setEventItems);
	},

	destroyed() {
		this.$puiEvents.$off('track:updateeventlegend', this.setEventItems);
	},

	methods: {
		previousSlide() {
			// Navega al carrusel anterior
			if (this.currentEvent > 0) {
				this.currentEvent--;
			}
		},

		nextSlide() {
			// Navega al siguiente carrusel
			if (this.currentEvent < this.eventItems.length - 1) {
				this.currentEvent++;
			}
		},

		changeFeatureProperty(index) {
			index++; // Recordemos que cuando procesamos no empezamos en 0 sino en 1
			ol2map.getLayerById(AIS_TRACK_EVENT_POINT_LAYER_ID).getSource() &&
				ol2map
					.getLayerById(AIS_TRACK_EVENT_POINT_LAYER_ID)
					.getSource()
					.forEachFeature(function (feature) {
						if (feature.getProperties().index.toString() == index.toString()) {
							feature.set('selected', true);
						} else {
							feature.set('selected', false);
						}
					});
		},

		validateDateFrom() {
			const isValid = this.validTimes();
			if (!isValid) {
				this.dateto = new Date(new Date(this.datefrom).getTime() + this.diffTime).toISOString();
			}
		},
		validateDateTo() {
			const isValid = this.validTimes();
			if (!isValid) {
				this.datefrom = new Date(new Date(this.dateto).getTime() - this.diffTime).toISOString();
			}
		},
		validTimes() {
			const differenceInMilliseconds = new Date(this.dateto) - new Date(this.datefrom);
			const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);

			const isValid = differenceInHours <= 48;
			return isValid;
		},
		setPanelOptionsData() {
			if (this.panelOptions.vistrack.dateFrom != null) {
				this.datefrom = this.panelOptions.vistrack.dateFrom;
			}
			if (this.panelOptions.vistrack.dateTo != null) {
				this.dateto = this.panelOptions.vistrack.dateTo;
			}
			this.eventItems = [];
			this.visposais = this.panelOptions.vistrack.visPosAis;
			this.vistrack = this.panelOptions.vistrack.visTrack;
			this.viswayevents = this.panelOptions.vistrack.visWayEvents;
		},

		setEventItems(items) {
			this.eventItems = items;
			this.getHeightCarousel();
		},
		getHeightCarousel() {
			setTimeout(() => {
				const item = document.getElementsByClassName('v-item-group');

				if (item && item.length > 0) {
					// Obtiene la altura del elemento
					this.carouselHeight = item[1].clientHeight + 50 + 'px';
				} else {
					this.carouselHeight = '0px';
				}
			}, '200');
		},

		updateBtn() {
			this.showTrack();
		},

		showTrack() {
			if (!this.visposais && !this.vistrack && !this.viswayevents) {
				this.$puiNotify.error(`${this.$t('operations.track.noVisSelected')}`);
				return;
			}
			if (!this.viswayevents) {
				this.eventItems = [];
			}
			this.panelOptions.vistrack.dateFrom = this.datefrom;
			this.panelOptions.vistrack.dateTo = this.dateto;
			this.panelOptions.vistrack.visPosAis = this.visposais;
			this.panelOptions.vistrack.visTrack = this.vistrack;
			this.panelOptions.vistrack.visWayEvents = this.viswayevents;
			this.panelOptions.vistrack.isClean = false;
			this.$store.commit('updateDynamicTabData', this.panelOptions);
			this.$store.commit('updateActivePanelAIS', this.panelOptions);
		}
	},

	computed: {
		showTrackMenu: {
			get() {
				return this.panelOptions.showTrackMenu;
			},

			set(value) {
				if (value) {
					this.showTrack();
				} else {
					this.eventItems = [];
					this.panelOptions.vistrack.isClean = true;
					this.$store.commit('updateDynamicTabData', this.panelOptions);
					this.$store.commit('updateActivePanelAIS', this.panelOptions);
				}
				// Update panel data
				this.panelOptions.showTrackMenu = value;
				this.$store.commit('updateDynamicTabData', this.panelOptions);
				this.$store.commit('updateActivePanelAIS', this.panelOptions);
			}
		}
	},
	watch: {
		panelOptions() {
			this.setPanelOptionsData();
		},
		currentEvent() {
			var item = this.eventItems[this.currentEvent];
			this.changeFeatureProperty(this.currentEvent);
			ol2map.centerInCoordinates(item.coordinates, this.zoomToCoordinates);
		}
	}
};
</script>

<style lang="postcss">
.track {
	background-color: #ddecf6;
	margin: 2px;
}
.v-label--required {
	font-weight: bold;
}
.v-carousel {
	background-color: #ddecf6;
}
.v-carousel .v-carousel__controls {
	top: 50px;
	height: auto;
	background-color: #ddecf6;
	align-items: start;
}
.v-carousel .v-carousel__controls .v-carousel__controls__item {
	align-items: start;
	margin: 0 2px; /* Espacio entre los controles */
	border-radius: 50%;
}
.v-carousel .v-carousel__controls .v-carousel__controls__item .v-btn__content {
	color: #ddecf6;
}
.v-carousel .v-carousel__controls .v-carousel__controls__item .v-btn__content > i {
	font-size: 12px !important;
	border: 2px solid var(--primarycolor); /* Grosor del borde del círculo */
	border-radius: 50%; /* Hacer que el borde sea circular */
	width: 1.4em; /* Ancho del borde ajustado al tamaño del font-size (1em) */
	height: 1.4em; /* Alto del borde ajustado al tamaño del font-size (1em) */
}
.v-carousel__controls__item.v-btn--active .v-icon {
	background-color: var(--primarycolor);
	color: var(--primarycolor);
}
.v-window {
	margin-bottom: 0 px;
	padding-bottom: 0 px;
	display: flex;
	flex-direction: column;
}
.v-window__prev {
	top: 0 !important;
}
.v-window__next {
	top: 0 !important;
}
.custom-carousel-item {
	margin: 0;
}
</style>
