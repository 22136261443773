<template>
	<v-dialog v-model="computedValue" scrollable max-width="700px">
		<v-card class="maplegenddialog pa-0">
			<v-toolbar flat height="44" class="mb-4">
				<v-toolbar-title class="legendtitle">{{ $t('legend.legend') }}</v-toolbar-title>
				<v-spacer></v-spacer>

				<v-btn @click="computedValue = false" small depressed min-width="36" width="36" height="36" class="mr-n3">
					<v-icon size="15">far fa-times</v-icon>
				</v-btn>
			</v-toolbar>

			<v-card-text>
				<v-row>
					<v-col cols="6">
						<span>
							<b class="font-weight-bold text-subtitle-2">{{ $t('gisweb.legend.dates.title') }}</b> <br />
							<Item :text="$t('gisweb.legend.dates.today')" :color="'#7adb98'" class="mt-4"></Item>
							<Item :text="$t('gisweb.legend.dates.tomorrow')" :color="'#69b9ff'"></Item>
						</span>
					</v-col>
					<v-col cols="6">
						<span>
							<b class="font-weight-bold text-subtitle-2">{{ $t('gisweb.legend.shiptypes.title') }}</b> <br />
							<div class="scrollable-container">
								<Item
									v-for="(type, i, index) in typeColors.legendItemsBody"
									:key="i"
									:text="type.text"
									:color="type.color"
									:class="index == 0 ? 'mt-4' : ''"
								>
								</Item>
							</div>
						</span>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import Item from './GiswebLegendDialogItem';

export default {
	name: 'GiswebLegend',

	props: {
		value: {
			type: Boolean,
			required: false
		},
		typeColors: {
			type: Object,
			required: false
		}
	},

	components: {
		Item
	},

	computed: {
		computedValue: {
			get() {
				return this.value;
			},

			set(value) {
				this.$emit('input', value);
			}
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../styles/app-variables.pcss';
.scrollable-container {
	overflow-y: auto;
	padding-right: 8px; /* Opcional: para un mejor diseño y evitar el solapamiento con scrollbars */
}
</style>
