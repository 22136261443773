import giswebMapUtils from './giswebMapUtils';

/** Styles vessels bbdd */
import stopTypeStyle from '@/components/gisweb/sections/map/styles/vesselStyleType';
import stopStatusStylePointType from '@/components/gisweb/sections/map/styles/vesselStylePointType';

/** 3rd-party */
import { Point } from 'ol/geom';

export default {
	/**
	 * Cambiar el estilo al elemento vessel de bbdd
	 * @param {Feature} feature
	 * @param {Float} resolution
	 * @param {Geometry} geometry
	 * @param {Feature} projLon
	 * @param {Float} projLat
	 */
	setStyleFeatureVesselBbdd: function (feature, resolution, geometry, projLon, projLat) {
		const drawAsFarSize = giswebMapUtils.drawAsFarSize(feature, resolution);
		if (drawAsFarSize) {
			feature.setGeometry(new Point([projLon, projLat]));
			feature.setStyle(stopStatusStylePointType);
		} else {
			feature.setGeometry(geometry);
			feature.setStyle(stopTypeStyle);
		}
	}
};
