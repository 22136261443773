<template>
	<div class="mx-4">
		<div id="appActionHeaderTest">
			<pui-select
				v-if="ports.length > 1"
				v-model="portmodel"
				:items="ports"
				itemValue="portid"
				itemText="portname"
				hide-details
				toplabel
				:placeholder="$t('gisweb.ports.title')"
				reactive
			></pui-select>
			<pui-select
				v-model="sessionLanguage"
				:controller="$store.getters.langController"
				modelName="puilanguage"
				:itemsToSelect="[{ isocode: sessionLanguage.language }]"
				:modelFormMapping="{ isocode: 'language' }"
				:fixedFilter="{
					groupOp: 'and',
					groups: [],
					rules: [{ field: 'enabled', op: 'eq', data: 1 }]
				}"
				itemValue="isocode"
				itemText="name"
				:clearable="false"
				noeditable
				toplabel
				@change="changeAppLang(sessionLanguage.language)"
			></pui-select>
		</div>
		<v-tabs v-model="tabmodel" slider-color="primary">
			<v-tab v-for="tab in filteredTabs" :key="tab.key" :href="`#${tab.key}`">
				{{ $t(tab.title) }}
			</v-tab>
		</v-tabs>

		<v-divider class="mb-1"></v-divider>

		<v-tabs-items v-model="tabmodel">
			<v-tab-item v-for="tab in filteredTabs" :key="tab.key" :value="tab.key">
				<gisweb-tab
					:mapInitialVariables="mapInitialVariables"
					:typeColors="typeColors"
					:tempoRefresh="tempoRefresh"
					:isVisiblePanels="tab.isVisiblePanels"
					:isVisibleMap="tab.isVisibleMap"
					:isVisibleTimeFilter="tab.isVisibleTimeFilter"
					:model="tab.model"
					:tabKey="tab.key"
				/>
			</v-tab-item>
		</v-tabs-items>
	</div>
</template>

<script>
import GiswebTab from './sections/GiswebTab.vue';

import {
	MODEL_STOPENTRY,
	MODEL_STOPEXIT,
	MODEL_STOPFORECAST,
	MODEL_STOPANNOUNCED,
	MODEL_STOPANCHORAGE,
	MODEL_STOPINITIATED,
	MODEL_STOPHISTORICAL
} from './utils/modelsConstants.js';

import {
	TAB_KEY_ENTRY,
	TAB_KEY_EXIT,
	TAB_KEY_FORECAST,
	TAB_KEY_ANNOUNCED,
	TAB_KEY_ANCHORAGE,
	TAB_KEY_INITIATED,
	TAB_KEY_HISTORICAL
} from './utils/tabKeysConstants.js';

import { MIN_ZOOM, MAX_ZOOM, INITIAL_MAP_ANGLE, SOURCE_PROJECTION, DESTINATION_PROJECTION } from './utils/mapVariables.js';

export default {
	name: 'GiswebContainer',

	components: {
		GiswebTab
	},

	data() {
		return {
			typeColors: null,
			mapInitialVariables: null,
			defaultTempoRefresh: 60,
			tempoRefresh: 60,
			filter: {
				dateini: null,
				dateend: null,
				shiptype: [],
				berth: [],
				text: null
			},
			tabs: [
				{
					key: TAB_KEY_ENTRY,
					title: 'gisweb.entry.title',
					functionality: 'READ_GISWEB_ENTRY',
					model: MODEL_STOPENTRY,
					isVisibleTimeFilter: false
				},
				{
					key: TAB_KEY_EXIT,
					title: 'gisweb.exit.title',
					functionality: 'READ_GISWEB_EXIT',
					model: MODEL_STOPEXIT,
					isVisibleTimeFilter: false
				},
				{
					key: TAB_KEY_FORECAST,
					title: 'gisweb.forecast.title',
					functionality: 'READ_GISWEB_FORECAST',
					model: MODEL_STOPFORECAST,
					isVisibleTimeFilter: false,
					isVisibleMap: false
				},
				{
					key: TAB_KEY_ANNOUNCED,
					title: 'gisweb.announced.title',
					functionality: 'READ_GISWEB_ANNOUNCED',
					model: MODEL_STOPANNOUNCED,
					isVisibleMap: false
				},
				{
					key: TAB_KEY_ANCHORAGE,
					title: 'gisweb.anchorage.title',
					functionality: 'READ_GISWEB_ANCHORAGE',
					model: MODEL_STOPANCHORAGE
				},
				{
					key: TAB_KEY_INITIATED,
					title: 'gisweb.initiated.title',
					functionality: 'READ_GISWEB_INITIATED',
					model: MODEL_STOPINITIATED
				},
				{
					key: TAB_KEY_HISTORICAL,
					title: 'gisweb.historical.title',
					functionality: 'READ_GISWEB_HISTORICAL',
					model: MODEL_STOPHISTORICAL,
					isVisiblePanels: false,
					isVisibleMap: false
				}
			],
			filteredTabs: [],
			sessionLanguage: { language: this.$store.getters.getSession.language, isocode: this.$store.getters.getSession.isocode }
		};
	},

	computed: {
		tabmodel: {
			get() {
				return this.$store.getters.getTabModel;
			},
			set(value) {
				this.$store.commit('setTabModel', value);
			}
		},
		portmodel: {
			get() {
				return this.$store.getters.getPortModel;
			},
			set(value) {
				this.$store.commit('setPortModel', value ? value.portid : null);
			}
		},
		ports() {
			return this.$store.state.session.properties.PORTS.filter(
				(port) => port.portauthorityid == window.localStorage.getItem('workingPortAuthorityId')
			);
		}
	},

	created() {
		this.filteredTabs = this.tabs.filter((tab) => this.$store.getters.hasFunctionality(tab.functionality));
		this.getTempoRefreshAuthority();
		this.getMapInitialVariables();
		this.getTypeColorsAutority();
	},

	methods: {
		getTempoRefreshAuthority() {
			const opts = {
				model: 'portauthorityvariable',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'variable',
							op: 'eq',
							data: 'TEMPO_REFRESH_MAP'
						},
						{
							field: 'portauthorityid',
							op: 'eq',
							data: window.localStorage.getItem('workingPortAuthorityId')
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data.length > 0) {
					this.tempoRefresh = Number(response.data.data[0].value) || this.defaultTempoRefresh;
				} else {
					this.tempoRefresh = this.defaultTempoRefresh;
				}
			});
		},
		/**
		 * Map Initial Variables
		 */
		getMapInitialVariables() {
			const workingPortsId = window.localStorage.getItem('workingPortsId');

			const portExtension = this.$store.state.session.properties.PORT_AUTHORITIES.find(
				(port) => port.id == window.localStorage.getItem('workingPortAuthorityId')
			);

			if (workingPortsId) {
				this.mapInitialVariables = {
					MIN_ZOOM: MIN_ZOOM,
					MAX_ZOOM: MAX_ZOOM,
					INITIAL_MAP_ANGLE: INITIAL_MAP_ANGLE,
					MAX_EXTENT: portExtension.bbox,
					SOURCE_PROJECTION: SOURCE_PROJECTION,
					DESTINATION_PROJECTION: DESTINATION_PROJECTION
				};
			}
		},
		getTypeColorsAutority() {
			const opts = {
				model: 'shiptypepa',
				page: 1,
				rows: null
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				const typeColors = response.data.data.reduce((json, data) => {
					// Utilizamos el valor de la propiedad 'visitstatuscode' como clave del JSON
					const key = data.code;
					// Asignamos el objeto actual al JSON con la clave correspondiente
					json[key] = {
						color: data.colorhex,
						coloroutline: data.colorhexoutline,
						text: data.description.toUpperCase(),
						shape: 'point'
					};
					// Retornamos el JSON actualizado
					return json;
				}, {});

				const unknowText = this.$t('gisweb.legend.shiptypes.unknown');
				typeColors['unknow'] = {
					color: '#cccccc',
					coloroutline: '#000000',
					text: unknowText,
					shape: 'point',
					code: null
				};

				this.typeColors = { legendItemsBody: typeColors };
			});
		},
		changeAppLang(lang) {
			window.localStorage.setItem('pui9_lang', lang);
			this.$store.commit('setUserLanguage', lang);
			this.$store.commit('setHttpRequestHeaders', { acceptLanguage: lang });
			this.$puiEvents.$emit('onPuiChangedLang', lang);
		}
	}
};
</script>

<style>
#appActionHeaderTest {
	padding: 10px;
	width: 450px;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 200;
	display: flex;
}
</style>
