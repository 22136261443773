<template>
	<div>
		<pui-datatable v-if="!masterDetail" :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<pui-datatable
			v-else
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
			:externalFilter="externalFilter"
			:masterDetail="masterDetail"
			:parentModelName="parentModelName"
			:modalDialog="modalDialog"
			:readOnly="readOnly"
			:showCreateBtn="showCreateBtn"
			:showDeleteBtn="showDeleteBtn"
		></pui-datatable>
		<pelppproduct-modals :modelName="modelName"></pelppproduct-modals>
	</div>
</template>

<script>
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import pelppproductActions from './PelppproductActions';
import pelppproductModals from './PelppproductModals.vue';

export default {
	name: 'pelppproduct-grid',
	components: {
		'pelppproduct-modals': pelppproductModals
	},
	mixins: [PuiGridDetailMixin],
	data() {
		return {
			modelName: 'pelppproduct',
			actions: pelppproductActions.gridactions,
			modelColumnDefs: {
				family: {
					render: (data, type, row) => {
						if (!row.family.includes('family')) {
							return this.$t(`pelppproduct.outsideterminal`);
						}
						return this.$t(`pelppproduct.${row.family}`);
					}
				},
				producttype: {
					render: (data, type, row) => {
						let type2 = row.producttype.toLowerCase();
						return this.$t(`pelppproduct.${type2}`);
					}
				},
				heavy: {
					render: (data, type, row) => {
						return row.heavy ? `<i class="fas fa-check state-check"></i>` : '';
					}
				}
			}
		};
	}
};
</script>
