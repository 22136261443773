import polygonIntersects from '@turf/boolean-intersects';
import { polygon as turfPolygon } from '@turf/helpers';
import { toLonLat } from 'ol/proj';
import { transform } from 'ol/proj';

const minToBow = 40;
const minToStern = 40;
const farSize = 10;

/**
 * Calculate the ratio between distances in real world and Web Mercator projection by an increment of 5 degrees
 * @type {Array}
 */
const WEB_MERCATOR_RATIOS = [
	[0, 100, 100.7],
	[5, 100.4, 101.1],
	[10, 101.6, 102.2],
	[15, 103.5, 104.2],
	[20, 106.4, 107.1],
	[25, 110.3, 110.9],
	[30, 115.4, 116],
	[35, 122, 122.5],
	[40, 130.4, 130.9],
	[45, 141.2, 141.7],
	[50, 155.3, 155.8],
	[55, 174, 174.4],
	[60, 199.6, 199.9],
	[65, 236.1, 236.4],
	[70, 291.6, 291.9],
	[75, 385.3, 385.5],
	[80, 574.2, 574.4]
];

export default {
	createPoint(x, y) {
		return {
			x,
			y
		};
	},
	calcSlope(point1, point2) {
		return Math.atan((point2.y - point1.y) / (point2.x - point1.x)) + Math.PI / 2;
	},
	getMiddlePoint: function (point1, point2) {
		var ptoMedio = {};

		ptoMedio.x = (point1.x + point2.x) / 2;
		ptoMedio.y = (point1.y + point2.y) / 2;

		return ptoMedio;
	},
	overlaps(polygonCoordsArray1, polygonCoordsArray2) {
		return polygonIntersects(turfPolygon([polygonCoordsArray1]), turfPolygon([polygonCoordsArray2]));
	},

	/**
	 * Transforma una distancia en metros en un plano a la distancia correspondiente en la proyección de Mercator.
	 *
	 * Hay una tabla pregenerada en pogs.ol con la distorsión 100 metros cada 5 grados
	 *
	 * @param  {double}  lat        La latitud a la que se va a pintar la distancia
	 * @param  {double}  realMeters La distancia real en metros
	 * @return {double}             Los metros en la proyección de Mercator
	 */
	getMetersMercator(lat, realMeters) {
		const point4258 = toLonLat([lat, lat]);
		const posInArray = Math.round(Math.abs(point4258[1]) / 5);
		const WEB_MERCATOR_RATIO = WEB_MERCATOR_RATIOS[posInArray];

		if (!WEB_MERCATOR_RATIO) {
			return 0;
		}

		return (realMeters * WEB_MERCATOR_RATIO[1]) / 100;
	},

	projectCoordinates(lon, lat) {
		return transform([lon, lat], 'EPSG:4326', 'EPSG:3857');
	},

	/**
	 * Check how show be draw the feature
	 * @param {OpenLayers.Feature.Vector} feature
	 * @return {Number}
	 */
	drawAsFarSize(feature, resolution) {
		var output = true;
		var dpr = this.getToBow(feature);
		var dpp = this.getToStern(feature);

		if ((dpr + dpp) / resolution > farSize) {
			output = false;
		}

		return output;
	},

	/**
	 * Distancia del receptor a proa
	 * @param {OpenLayers.Feature.Vector} feature
	 * @return {Number}
	 */
	getToBow: function (feature) {
		if (feature.getProperties().to_bow !== undefined && feature.getProperties().to_bow !== null) {
			return feature.attributes.to_bow;
		}
		return minToBow;
	},

	/**
	 * Distancia del receptor a popa
	 * @param {OpenLayers.Feature.Vector} feature
	 * @return {Number}
	 */
	getToStern: function (feature) {
		return feature.getProperties().to_stern || minToStern;
	},

	/**
	 * Polygon WKT to extent Openlayers format
	 * @param {String} polygon
	 * @return {Array}
	 */
	polygonWktToOlExtent: function (polygon) {
		const bboxCoords = polygon.replace('POLYGON((', '').replace('POLYGON ((', '').replace('))', '').replaceAll(', ', ',').split(',');
		const bottomLeft = bboxCoords[0].split(' ').map((coord) => parseInt(coord));
		const topRight = bboxCoords[2].split(' ').map((coord) => parseInt(coord));
		const fitExtent = [...bottomLeft, ...topRight];
		return fitExtent;
	}
};
