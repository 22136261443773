<template>
	<div class="scrollable-container">
		<b class="font-weight-bold text-subtitle-2">{{ $t('gisweb.legend.shiptypes.title') }}:</b>
		<Item v-for="(type, i) in legendItemsFiltered" :key="i" :text="type.text" :color="type.color" />
	</div>
</template>

<script>
import Item from './GiswebLegendDialogShipTypesItem';

export default {
	name: 'GiswebShipTypesLegend',
	props: {
		value: {
			type: Boolean,
			required: false
		},
		typeColors: {
			type: Object,
			required: false
		},
		stopsProcessedByCreator: {
			type: Array,
			required: true
		}
	},

	components: {
		Item
	},

	computed: {
		legendItemsFiltered() {
			// Filtrar los elementos de la leyenda según los tipos de barcos presentes en los datos
			const filteredLegendItems =
				this.typeColors &&
				Array.from(
					new Set(
						this.stopsProcessedByCreator
							.map((stop) => stop.vesseltype || 'unknow')
							.filter((vesselType) => vesselType && this.typeColors.legendItemsBody[vesselType])
							.map((vesselType) => this.typeColors.legendItemsBody[vesselType])
					)
				);
			return filteredLegendItems;
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../styles/app-variables.pcss';

.scrollable-container {
	margin: 0px;
	padding: 0px;
	display: flex;
	flex-wrap: wrap; /* Ajusta los elementos en filas */
	gap: 4px; /* Espaciado entre elementos */
	align-items: center; /* Asegura que los elementos se alineen verticalmente */
}

.scrollable-container > b {
	font-size: 14px; /* Opcional: Ajustar tamaño para consistencia */
}
</style>
