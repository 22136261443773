import { render, staticRenderFns } from "./GiswebDetail.vue?vue&type=template&id=427d0570&scoped=true"
import script from "./GiswebDetail.vue?vue&type=script&lang=js"
export * from "./GiswebDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "427d0570",
  null
  
)

export default component.exports