<template>
	<div>
		<v-row dense class="pb-2">
			<div class="portcallnumber pa-2 font-weight-black">
				{{ stop.portcallnumber }}
			</div>
			<div class="font-weight-black pa-2 mr-auto">
				{{ stop.vesselname }}
			</div>
			<div>
				<v-btn class="elevation-0" x-small @click="goToForm()">
					<v-icon small>fa-kit fa-line-information-consultation</v-icon>
				</v-btn>
			</div>
		</v-row>
		<v-divider class="mb-2"></v-divider>
		<v-row dense>
			<v-col>
				<gisweb-field :label="$t('gisweb.entry.berthname')" :value="stop.berthname"></gisweb-field>
			</v-col>
			<v-col>
				<gisweb-field
					:label="$t('gisweb.card.entry')"
					:value="convertDate(entryDateFromStatus(stop)) || ''"
					:day="getToday(entryDateFromStatus(stop))"
				></gisweb-field>
			</v-col>
			<v-col>
				<gisweb-field
					:label="$t('gisweb.card.exit')"
					:value="convertDate(exitDateFromStatus(stop)) || ''"
					:day="getToday(exitDateFromStatus(stop))"
				>
				</gisweb-field>
			</v-col>

			<v-col cols="4">
				<gisweb-field :label="$t('gisweb.entry.consignee')" :value="stop.consigneename"></gisweb-field>
			</v-col>
			<v-col cols="2">
				<gisweb-field :label="$t('gisweb.entry.vessellength')" :value="stop.vessellength"></gisweb-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import GiswebField from '@/components/gisweb/sections/cards/GiswebField.vue';
export default {
	components: {
		GiswebField
	},
	props: {
		stop: {
			type: Object,
			required: true
		}
	},
	methods: {
		entryDateFromStatus(stop) {
			switch (stop.statusid) {
				case 'IN':
					return stop.ata;
				case 'PL':
					return stop.etaplan;
				case 'AU':
					return stop.etaauth;
				default:
					return stop.eta;
			}
		},
		exitDateFromStatus(stop) {
			switch (stop.statusid) {
				case 'PL':
					return stop.etdplan;
				case 'AU':
					return stop.etdauth;
				case 'FI':
					return stop.atd;
				default:
					return stop.etd;
			}
		},
		convertDate(date) {
			return this.$dateTimeUtils.getLocalFormattedDate(date, `${this.$store.getters.dateFormat} ${this.$store.getters.timeFormat}`);
		},
		goToForm() {
			localStorage.setItem('stopGisweb', JSON.stringify(this.stop));
			this.$router.push({
				path: '/gisweb/create/e30='
			});
		},
		getToday(date) {
			const inputDate = new Date(date);
			const today = new Date();
			const tomorrow = new Date();
			tomorrow.setDate(today.getDate() + 1);

			const isToday = inputDate.toDateString() == today.toDateString();
			const isTomorrow = inputDate.toDateString() == tomorrow.toDateString();

			if (isToday) {
				return 'today';
			} else if (isTomorrow) {
				return 'tomorrow';
			} else {
				return null;
			}
		}
	}
};
</script>

<style>
@import '../../../../styles/app-variables.pcss';

.portcallnumber {
	background-color: #d7ebff;
	border-radius: 5px;
}

.expansionactive .portcallnumber {
	background-color: var(--primary);
	color: white;
}

.expansionactive .theme--light.v-divider {
	border-color: var(--primary);
}
</style>
