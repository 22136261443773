<template>
	<span class="legend-item">
		<v-icon :color="color" x-small class="icon">fas fa-circle</v-icon>
		<p class="itemText">{{ text }}</p>
	</span>
</template>

<script>
export default {
	props: ['text', 'color']
};
</script>

<style lang="postcss">
@import '../../../styles/app-variables.pcss';

.legend-item {
	display: flex; /* Flexbox para alinear elementos */
	align-items: center; /* Centra verticalmente los elementos en el eje Y */
}

.icon {
	padding-left: 2px;
	font-size: 12px; /* Ajusta el tamaño del ícono */
	line-height: 1; /* Elimina espacios extra */
	margin-right: 4px; /* Espaciado entre ícono y texto */
	display: inline-flex; /* Asegura que el ícono respete las reglas de flexbox */
}

.itemText {
	height: 0px;
	font-size: 12px; /* Ajusta el tamaño del texto */
	line-height: 1; /* Asegura que no haya espacio extra */
	margin: 0; /* Elimina márgenes predeterminados */
	display: inline-flex; /* Mantiene alineación con el ícono */
}
</style>
