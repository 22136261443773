<template>
	<div class="pui-form">
		<v-form class="" ref="form">
			<v-row class="mt-4 pui-form-layout">
				<pui-field-set :title="$t('gisweb.detail.shipdata.title')" style="width: 100%">
					<v-row dense>
						<v-col cols="3">
							<pui-text-field
								:id="`vesselname-giswebdetail`"
								v-model="stop.shipdata.name"
								:label="$t('gisweb.detail.shipdata.vesselname')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="3">
							<pui-text-field
								:id="`vesseltype-giswebdetail`"
								v-model="stop.vesseltypedescription"
								:label="$t('gisweb.detail.shipdata.vesseltype')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="2">
							<pui-text-field
								:id="`vesselimo-giswebdetail`"
								v-model="stop.shipdata.imo"
								:label="$t('gisweb.detail.shipdata.vesselimo')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="3">
							<pui-text-field
								:id="`vesselflag-giswebdetail`"
								v-model="stop.flag"
								:label="$t('gisweb.detail.shipdata.vesselflag')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="1">
							<pui-text-field
								:id="`draftentrance-giswebdetail`"
								v-model="stop.draftentrance"
								:label="$t('gisweb.detail.shipdata.draftentrance')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="1">
							<pui-text-field
								:id="`draught-giswebdetail`"
								v-model="stop.shipdata.draught"
								:label="$t('gisweb.detail.shipdata.draught')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="1">
							<pui-text-field
								:id="`vesselgt-giswebdetail`"
								v-model="formattedQuantity"
								:label="$t('gisweb.detail.shipdata.vesselgt')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="1">
							<pui-text-field
								:id="`vesselbeam-giswebdetail`"
								v-model="stop.shipdata.beam"
								:label="$t('gisweb.detail.shipdata.vesselbeam')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="1">
							<pui-text-field
								:id="`vessellength-giswebdetail`"
								v-model="stop.shipdata.length"
								:label="$t('gisweb.detail.shipdata.vessellength')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
					</v-row>
				</pui-field-set>
				<pui-field-set :title="$t('gisweb.detail.stopdata.title')" style="width: 100%">
					<v-row dense>
						<v-col cols="2">
							<pui-text-field
								:id="`portcallnumber-giswebdetail`"
								v-model="stop.portcallnumber"
								:label="$t('gisweb.detail.stopdata.portcallnumber')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="3">
							<pui-text-field
								:id="`consigneename-giswebdetail`"
								v-model="stop.consigneename"
								:label="$t('gisweb.detail.stopdata.consigneename')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="3">
							<pui-select
								:id="`fromportcode-giswebdetail`"
								:attach="`fromportcode-giswebdetail`"
								:label="$t('gisweb.detail.stopdata.fromportname')"
								toplabel
								clearable
								disabled
								v-model="stop"
								modelName="worldport"
								:modelFormMapping="{ code: 'fromportcode' }"
								:itemsToSelect="[{ code: stop.fromportcode }]"
								:itemValue="['code']"
								:itemText="(item) => `${item.code} - ${item.name} (${item.countrycode})`"
								reactive
							></pui-select>
						</v-col>
						<v-col cols="3">
							<pui-select
								:id="`toportcode-giswebdetail`"
								:attach="`toportcode-giswebdetail`"
								:label="$t('gisweb.detail.stopdata.toportname')"
								toplabel
								clearable
								disabled
								v-model="stop"
								modelName="worldport"
								:modelFormMapping="{ code: 'toportcode' }"
								:itemsToSelect="[{ code: stop.toportcode }]"
								:itemValue="['code']"
								:itemText="(item) => `${item.code} - ${item.name} (${item.countrycode})`"
								reactive
							></pui-select>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="1">
							<pui-text-field
								:id="`num-giswebdetail`"
								v-model="stop.num"
								:label="$t('gisweb.detail.stopdata.num')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="2">
							<pui-text-field
								:id="`status-giswebdetail`"
								v-model="stop.status"
								:label="$t('gisweb.detail.stopdata.status')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="3">
							<pui-text-field
								:id="`berthname-giswebdetail`"
								v-model="stop.berthname"
								:label="$t('gisweb.detail.stopdata.berthname')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="3">
							<pui-text-field
								:id="`berthingtypename-giswebdetail`"
								v-model="stop.berthingtypename"
								:label="$t('gisweb.detail.stopdata.berthingtypename')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="1">
							<pui-text-field
								:id="`bollardini-giswebdetail`"
								v-model="stop.inibollard"
								:label="$t('gisweb.detail.stopdata.bollardini')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="1">
							<pui-text-field
								:id="`bollardend-giswebdetail`"
								v-model="stop.endbollard"
								:label="$t('gisweb.detail.stopdata.bollardend')"
								toplabel
								disabled
								maxlength="100"
							></pui-text-field>
						</v-col>
						<v-col cols="3">
							<pui-date-field
								:id="`entrydate-giswebdetail`"
								v-model="entryDate"
								:label="$t('gisweb.detail.stopdata.entrydate')"
								toplabel
								time
								disabled
							></pui-date-field>
						</v-col>
						<v-col cols="3">
							<pui-date-field
								:id="`departuredate-giswebdetail`"
								:value="departureDate"
								:label="$t('gisweb.detail.stopdata.departuredate')"
								toplabel
								time
								disabled
							></pui-date-field>
						</v-col>
					</v-row>
				</pui-field-set>
				<v-col cols="12">
					<pui-field-set :title="$t('gisweb.detail.operationsdata.title')" style="display: contents">
						<pui-master-detail
							componentName="giswebdetailgrid"
							parentModelName="stop"
							:parentPk="getPk()"
							:parentPkChildFk="{ id: 'stop_id' }"
							:modalDialog="true"
							:parentModel="stop"
							:showCreateBtn="false"
							:showDeleteBtn="false"
							:navigableDetail="false"
						></pui-master-detail>
					</pui-field-set>
				</v-col>
			</v-row>
		</v-form>
	</div>
</template>

<script>
import GisWebDetailGrid from './GiswebDetailGrid.vue';

export default {
	components: {
		GisWebDetailGrid
	},
	mixins: [],
	data() {
		return {
			selectedItems: {}
		};
	},
	props: {},
	created() {
		const stopData = localStorage.getItem('stopGisweb');
		if (stopData) {
			this.stop = JSON.parse(stopData);
		}
	},
	methods: {
		getPk() {
			return btoa(`{"id": ${this.stop.id}}`);
		},
		entryDateFromStatus(stop) {
			switch (stop.statusid) {
				case 'IN':
					return stop.ata;
				case 'PL':
					return stop.etaplan;
				case 'AU':
					return stop.etaauth;
				default:
					return stop.eta;
			}
		},
		exitDateFromStatus(stop) {
			switch (stop.statusid) {
				case 'PL':
					return stop.etdplan;
				case 'AU':
					return stop.etdauth;
				case 'FI':
					return stop.atd;
				default:
					return stop.etd;
			}
		}
	},
	computed: {
		entryDate() {
			return this.entryDateFromStatus(this.stop);
		},
		departureDate() {
			return this.exitDateFromStatus(this.stop);
		},
		formattedQuantity(value) {
			return new Intl.NumberFormat('es-ES').format(this.stop.shipdata.gt);
		}
	}
};
</script>

<style scoped></style>
