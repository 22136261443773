<template>
	<div class="pui-form">
		<v-form class="mb-4 pb-4 mt-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#maintab'">{{ $t('form.shippositioning.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#events'" v-if="!isCreatingElement && !isModalDialog">{{ $t('form.shippositioning.events') }}</v-tab>
				<v-tab :key="2" :href="'#eventsais'" v-if="!isCreatingElement && !isModalDialog">{{ $t('form.shippositioning.eventsais') }}</v-tab>
				<v-tab :key="3" :href="'#shippositioningchat'" v-if="!isCreatingElement && !isModalDialog">{{
					$t('form.shippositioning.chat')
				}}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'maintab'">
					<v-row class="pui-form-layout">
						<v-row class="mx-1 mt-3 d-flex">
							<v-col cols="3">
								<pui-select
									:id="`ship-shippositioning`"
									:attach="`ship-shippositioning`"
									:label="$t('form.shippositioning.ship')"
									:disabled="formDisabled"
									toplabel
									clearable
									modelName="ship"
									v-model="model.visit"
									reactive
									required
									:itemsToSelect="shipItemsToSelect"
									:modelFormMapping="{ id: 'shipid' }"
									:itemValue="['id']"
									:itemText="(item) => `${item.imo} - ${item.name}`"
									:order="{ name: 'asc' }"
									:fixedFilter="filterShipVersion"
									detailComponentName="ship-form"
									detailModelName="ship"
								></pui-select>
							</v-col>
							<v-col cols="3">
								<pui-text-field
									:id="`voyage-shippositioning`"
									v-model="model.objectperu.voyage"
									:label="$t('form.shippositioning.portcallnumber')"
									:disabled="formDisabled"
									toplabel
									required
									maxlength="50"
								>
								</pui-text-field>
							</v-col>
							<v-col cols="3">
								<pui-select
									:id="`berthid-shippositioning`"
									v-model="model.objectperu"
									:label="$t('form.shippositioning.berthid')"
									modelName="berth"
									toplabel
									:modelFormMapping="{ id: 'berthid' }"
									:itemsToSelect="berthidItemsToSelect"
									itemValue="id"
									:itemText="(item) => (item.berthsubcode ? `${item.name} - ${item.berthsubcode}` : item.name)"
									reactive
									required
									:disabled="formDisabled"
								></pui-select>
							</v-col>
							<v-col cols="3">
								<pui-select
									v-if="!isCreatingElement"
									:id="`visit-shippositioning`"
									:attach="`visit-shippositioning`"
									:label="$t('form.shippositioning.visit')"
									disabled
									toplabel
									clearable
									modelName="visit"
									v-model="model.objectperu"
									reactive
									:itemsToSelect="visitidItemsToSelect"
									:modelFormMapping="{ id: 'visitid' }"
									:itemValue="['id']"
									:itemText="(item) => `${item.portcallnumber}`"
									:order="{ name: 'asc' }"
									:fixedFilter="filterVisitShip"
								></pui-select>
							</v-col>
						</v-row>
						<pui-field-set :title="$t('form.shippositioning.estimated')">
							<v-row class="mx-1 d-flex justify-space-between">
								<v-col class="flex-grow-1">
									<pui-date-field
										:id="`eta-shippositioning`"
										v-model="model.visit.eta"
										:label="$t('form.shippositioning.eta')"
										:disabled="formDisabled"
										required
										toplabel
										time
									></pui-date-field>
								</v-col>
								<v-col class="flex-grow-1">
									<pui-date-field
										:id="`etb-shippositioning`"
										v-model="model.objectperu.etb"
										:label="$t('form.shippositioning.etb')"
										:disabled="formDisabled"
										required
										toplabel
										time
										:min="!formDisabled && isCreatingElement ? model.visit.eta : null"
									></pui-date-field>
								</v-col>
								<v-col class="flex-grow-1">
									<pui-date-field
										:id="`etc-shippositioning`"
										v-model="model.objectperu.etc"
										:label="$t('form.shippositioning.etc')"
										:disabled="formDisabled"
										toplabel
										time
										:min="!formDisabled && isCreatingElement ? model.objectperu.etaterminal : null"
									></pui-date-field>
								</v-col>

								<v-col class="flex-grow-1">
									<pui-date-field
										:id="`etc-shippositioning`"
										v-model="model.objectperu.etd"
										:label="$t('form.shippositioning.etd')"
										:disabled="formDisabled"
										required
										toplabel
										time
										:max="!formDisabled && isCreatingElement ? model.visit.etd : null"
									></pui-date-field>
								</v-col>
							</v-row>
						</pui-field-set>
						<pui-field-set :title="$t('form.shippositioning.real')">
							<v-row class="mx-1 d-flex justify-space-between">
								<v-col class="flex-grow-1">
									<pui-date-field
										:id="`ata-shippositioning`"
										v-model="model.visit.ata"
										:label="$t('form.shippositioning.ata')"
										:disabled="formDisabled"
										toplabel
										time
									></pui-date-field>
								</v-col>
								<v-col class="flex-grow-1">
									<pui-date-field
										:id="`atb-shippositioning`"
										v-model="model.objectperu.atb"
										:label="$t('form.shippositioning.atb')"
										:disabled="formDisabled"
										toplabel
										time
										:min="!formDisabled && isCreatingElement ? model.visit.ata : null"
									></pui-date-field>
								</v-col>
								<v-col class="flex-grow-1">
									<pui-date-field
										:id="`ats-shippositioning`"
										v-model="model.objectperu.ats"
										:label="$t('form.shippositioning.ats')"
										:disabled="formDisabled"
										toplabel
										time
									></pui-date-field>
								</v-col>
								<v-col class="flex-grow-1">
									<pui-date-field
										:id="`atc-shippositioning`"
										v-model="model.objectperu.atc"
										:label="$t('form.shippositioning.atc')"
										:disabled="formDisabled"
										toplabel
										time
										:min="!formDisabled && isCreatingElement ? model.objectperu.ats : null"
									></pui-date-field>
								</v-col>
								<v-col class="flex-grow-1">
									<pui-date-field
										:id="`atd-shippositioning`"
										v-model="model.objectperu.atd"
										:label="$t('form.shippositioning.atd')"
										:disabled="formDisabled"
										toplabel
										time
										:min="!formDisabled && isCreatingElement ? model.objectperu.ata : null"
									></pui-date-field>
								</v-col>
							</v-row>
						</pui-field-set>
					</v-row>
					<v-row dense class="pui-form-layout">
						<custom-fields-generate-form
							:formDisabled="formDisabled"
							:isCreatingElement="isCreatingElement"
							:parentObject="model"
							:parentModelName="modelName"
							:parentPk="pk"
						></custom-fields-generate-form>
					</v-row>
				</v-tab-item>
				<v-tab-item :key="1" :value="'events'" lazy v-if="!isCreatingElement && !isModalDialog">
					<div class="d-flex justify-center">
						<events-template :visit="model.visit"></events-template>
					</div>
				</v-tab-item>
				<v-tab-item :key="2" lazy :value="'eventsais'" v-if="!isCreatingElement && !isModalDialog"
					><pui-master-detail
						componentName="logaiseventextendedgrid"
						:parentModelName="modelName"
						:parentPk="pk"
						:parentPkChildFk="{ id: 'visitid' }"
						:formDisabled="true"
						:modalDialog="isModalDialog"
						:parentModel="model"
					></pui-master-detail>
				</v-tab-item>
				<v-tab-item :key="3" lazy :value="'shippositioningchat'" v-if="!isCreatingElement && !isModalDialog">
					<operations-chat :visit="visitChat"></operations-chat>
				</v-tab-item>
			</v-tabs-items>
			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import operationsChat from '@/components/chat/OperationsChat';
import EventsTemplate from '@/components/visit/EventsTemplate';
export default {
	mixins: [PuiFormMethodsMixin],
	name: 'shippositioning-form',
	components: { operationsChat, EventsTemplate },
	data() {
		return {
			modelName: 'shippositioning',
			visitChat: {}
		};
	},
	computed: {
		shipItemsToSelect() {
			return [{ id: this.model.visit.shipid }];
		},
		visitidItemsToSelect() {
			return [{ id: this.model.objectperu.visitid }];
		},
		berthidItemsToSelect() {
			return [{ id: this.model.objectperu.berthid }];
		},
		filterShipVersion() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'next_version_id', op: 'nu', data: null }]
			};
		},
		filterVisitShip() {
			let date = new Date();
			let dateEnd = new Date();
			dateEnd.setDate(date.getDate() + 15);
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'statusid', op: 'ne', data: 'FI' }]
			};
		}
	},
	mounted() {
		const waitModel = setInterval(() => {
			if (this.model && this.model != undefined && this.model != null) {
				this.getVisitChat();
				clearInterval(waitModel);
				if (this.model.statusid == 'AN') {
					this.formDisabled = true;
				}
			}
		}, 50);
	},
	methods: {
		getVisitChat() {
			const opts = {
				model: 'visit',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [
						{
							field: 'id',
							op: 'eq',
							data: this.model.objectperu.visitid
						}
					]
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data) {
					this.visitChat = response.data.data[0];
				}
			});
		}
	},
	afterGetData() {},
	destroyed() {}
};
</script>

<style scoped>
h1 {
	color: blue;
}
</style>
