<template>
	<div class="tabContainer">
		<gisweb-toolbar :isVisibleTimeFilter="isVisibleTimeFilter" :tabKey="tabKey" @update:togglefilters="updateTabExpanded" />
		<v-row class="d-flex justify-space-around rowContainer">
			<v-col class="listContainer" :cols="listContainerCols" :class="{ expandedTab: !visibleFilters }">
				<update-control v-if="tempoRefresh != null" :tempoRefresh="tempoRefresh" @update:refreshstops="getStops" />
				<div style="overflow-y: auto">
					<span class="d-flex justify-space-between">
						<GiswebExportBtn
							:modelName="model"
							:headers="headers"
							:items="this.visibleMap ? stopsProcessedByCreator : stopsToProcessed"
							:printMap="this.visibleMap"
							:mapManager="this.visibleMap ? mapManager : null"
							:dynamicMapRef="dynamicMapRef"
						/>
						<span class="d-flex mt-4">
							<Item :text="$t('gisweb.entry.today')" :color="'#7adb98'" class="me-2"></Item>
							<Item :text="$t('gisweb.entry.tomorrow')" :color="'#69b9ff'"></Item>
						</span>
						<span class="d-flex">
							<pui-switch class="mr-2" @input="updateAllPanelsOpen" :label="$t('gisweb.entry.switchtitle')"></pui-switch>
							<v-btn class="elevation-0 mr-4" style="background-color: var(--low)" @click="orderStops">
								<v-icon small>fa-kit fa-line-order-1</v-icon>
							</v-btn>
							<v-btn class="elevation-0 mr-4" style="background-color: var(--low)" @click="toggleMap" v-if="isVisibleMap">
								<v-icon>{{ mapIcon }}</v-icon>
							</v-btn>
							<v-btn class="elevation-0" color="primary" @click="modalOpened = true">
								<v-icon small>fa-kit fa-line-information-consultation</v-icon>
							</v-btn>
						</span>
					</span>
					<v-expansion-panels multiple flat tile outlined v-model="panel" v-if="localVisiblePanels">
						<v-expansion-panel
							v-for="(item, i) in visibleMap ? stopsProcessedByCreator : stopsToProcessed"
							:key="i"
							active-class="expansionactive"
							class="expansion"
							@click="togglePanel(i)"
						>
							<v-expansion-panel-header hide-actions>
								<card-header :stop="item" />
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<card-content :stop="item" />
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</div>
				<gisweb-legend v-model="modalOpened" :typeColors="typeColors" v-if="typeColors" />
			</v-col>
			<v-col cols="4" v-if="visibleMap" class="mapContainer" :class="{ expandedTab: !visibleFilters }">
				<div class="map-wrapper">
					<gisweb-map
						:dynamicMapRef="dynamicMapRef"
						:typeColors="typeColors"
						:mapInitialVariables="mapInitialVariables"
						:tabKey="tabKey"
						:stopsToProcessed="stopsToProcessed"
						@update:stopsProcessedByCreator="updateStopsProcessedByCreator"
						@update:elementToZoom="resetElementToZoom"
						:elementToZoom="elementToZoom"
						@map:mapManagerLoaded="handleMapManagerLoaded"
					/>
					<gisweb-ship-types-legend :typeColors="typeColors" :stopsProcessedByCreator="stopsProcessedByCreator" />
				</div>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import GiswebMap from './map/GiswebMap';
import UpdateControl from '../autorefresh/UpdateControl';
import GiswebToolbar from '../toolbar/GiswebToolbar.vue';
import CardHeader from './cards/CardHeader.vue';
import CardContent from './cards/CardContent.vue';
import GiswebLegend from '../legend/GiswebLegend.vue';
import { TAB_KEY_HISTORICAL } from '../utils/tabKeysConstants.js';
import GiswebExportBtn from './toolbar/GiswebExportBtn.vue';
import Item from '../legend/GiswebLegendDialogItem';
import GiswebShipTypesLegend from '../legend/GiswebShipTypesLegend';

export default {
	name: 'GiswebTab',

	components: {
		GiswebToolbar,
		CardHeader,
		CardContent,
		GiswebLegend,
		UpdateControl,
		GiswebExportBtn,
		GiswebMap,
		Item,
		GiswebShipTypesLegend
	},

	mixins: [],

	data() {
		return {
			dynamicMapRef: `mapContainer_giswebMap_${this.tabKey}_${this._uid}`,
			panel: [],
			isPanelClick: false, // Flag para evitar el bucle
			stopsToProcessed: [],
			stopsProcessedByCreator: [],
			modalOpened: false,
			asc: true,
			visibleMap: this.isVisibleMap,
			localVisiblePanels: this.isVisiblePanels,
			allPanelsOpen: false,
			activityHeaders: [],
			modelConfiguration: null,
			isBulkExpand: false,
			elementToZoom: null,
			mapManager: null,
			visibleFilters: true
		};
	},

	props: {
		model: {
			type: String,
			required: true
		},
		tabKey: {
			type: String,
			required: true
		},
		isVisibleMap: {
			type: Boolean,
			default: true
		},
		isVisibleTimeFilter: {
			type: Boolean,
			default: true
		},
		isVisiblePanels: {
			type: Boolean,
			default: true
		},
		tempoRefresh: {
			type: Number,
			required: true
		},
		mapInitialVariables: {
			type: Object,
			required: false
		},
		typeColors: {
			type: Object,
			required: false
		}
	},

	computed: {
		tabmodel: {
			get() {
				return this.$store.getters.getTabModel;
			}
		},
		portmodel: {
			get() {
				return this.$store.getters.getPortModel;
			}
		},
		filter: {
			get() {
				return this.$store.getters.getFilter(this.tabKey);
			}
		},
		isActiveTab() {
			return this.tabmodel === this.tabKey;
		},
		headers() {
			return [...this.masterProviderHeaders(), ...this.activityHeaders];
		},
		mapIcon() {
			return this.visibleMap ? 'fa-sharp fa-solid fa-expand-alt' : 'fa-sharp fa-solid fa-compress-alt';
		},
		listContainerCols() {
			return this.visibleMap ? 7 : 11;
		},
		isTabKeyHistorical() {
			return this.tabKey === TAB_KEY_HISTORICAL;
		}
	},

	watch: {
		tabmodel() {
			this.refreshData();
		},

		portmodel() {
			this.refreshData();
		},

		panel(newVal, oldVal) {
			if (this.isBulkExpand) {
				return; // Ignora cambios masivos
			}

			if (this.isPanelClick) {
				this.isPanelClick = false;

				// Detectar qué panel se expandió o colapsó
				const expandedPanels = newVal.filter((panel) => !oldVal.includes(panel));
				if (expandedPanels.length === 1) {
					this.panel = expandedPanels; // Colapsa los demás paneles
					// Zoom to element
					this.elementToZoom = this.visibleMap ? this.stopsProcessedByCreator[expandedPanels[0]] : this.stopsToProcessed[expandedPanels[0]];
				}
			}
		},
		filter: {
			handler(newFilter) {
				if (!this.isActiveTab) {
					return;
				}
				// Ponemos el update control a 0 cada vez que se canvia el filtro
				this.$puiEvents.$emit('update:refreshprogress');

				if (this.isTabKeyHistorical) {
					const hasValidDateFilter = newFilter.dateini && newFilter.dateend;
					this.localVisiblePanels = hasValidDateFilter;
					if (hasValidDateFilter) {
						this.getStops();
					}
				} else {
					this.getStops();
				}
			},
			deep: true // Escucha cambios profundos dentro del objeto
		}
	},

	created() {
		if (this.localVisiblePanels) {
			this.getStops();
		}
	},

	methods: {
		refreshData() {
			if (this.isActiveTab) {
				// Ponemos el update control a 0 cada vez que se cambia el tabmodel
				this.$puiEvents.$emit('update:refreshprogress');

				if (this.localVisiblePanels) {
					this.getStops();
				}
			}
		},
		buildFilterRules() {
			const rules = [];

			this.filter.dateini ? rules.push({ field: this.isTabKeyHistorical ? 'ata' : 'eta', op: 'ge', data: this.filter.dateini }) : null;
			this.filter.dateend ? rules.push({ field: this.isTabKeyHistorical ? 'atd' : 'eta', op: 'le', data: this.filter.dateend }) : null;
			Array.isArray(this.filter.shiptype) && this.filter.shiptype.length > 0
				? rules.push({ field: 'vesseltype', op: 'in', data: this.filter.shiptype.map((item) => item.code) })
				: [];
			Array.isArray(this.filter.berth) && this.filter.berth.length > 0
				? rules.push({ field: 'berthid', op: 'in', data: this.filter.berth.map((item) => item.id) })
				: [];

			// Add port autority filter
			const portAuthority = this.$store.getters.getPortAuthorityId;
			rules.push({ field: 'port_authority', op: 'eq', data: portAuthority });

			// Add port filter
			this.portmodel ? rules.push({ field: 'port_id', op: 'eq', data: this.portmodel }) : null;

			return rules;
		},

		buildSearchRules() {
			const textFilter = this.filter.text || '';
			const fields = [
				'portcallnumber',
				'vesselname',
				'berthname',
				'consigneename',
				'vessellength',
				'operationtype',
				'loadtypename',
				'quantity',
				'stevedorecompanyname'
			];

			return fields.map((field) => ({
				field,
				op: 'cn',
				data: textFilter
			}));
		},
		buildGroupPa() {
			return {
				groups: [],
				groupOp: 'or',
				rules: this.buildSearchRules()
			};
		},
		getStops() {
			const rules = this.buildFilterRules();
			const groupPa = this.buildGroupPa();

			const opts = {
				model: this.model,
				filter: {
					groups: [groupPa],
					groupOp: 'and',
					rules: rules
				},
				order: [
					{
						column: 'eta',
						direction: 'asc'
					}
				]
			};
			this.fetchStops(opts);
		},
		fetchStops(opts) {
			this.$puiRequests.postRequest('/vstopoperations/getStopsGisweb', opts, (response) => {
				this.stopsToProcessed = response.data.data;
				this.updateActivityHeaders();
			});
			this.modelConfiguration = this.$store.getters.getModelByName('stopoperations');
		},
		updateActivityHeaders() {
			const rowsWithActivities = this.stopsProcessedByCreator.filter((row) => row.resourcesjson != null);

			if (this.isEmpty(this.activityHeaders) && rowsWithActivities.length > 0) {
				this.activityHeaders = JSON.parse(rowsWithActivities[0].resourcesjson)
					.map(mapActivityHeaders)
					.filter((row) => row.disabled !== 1);
			}
		},
		orderStops() {
			this.asc = !this.asc; // Alterna el valor de asc
			if (this.asc) {
				this.stopsToProcessed.sort((a, b) => {
					return new Date(this.entryDateFromStatus(a)) - new Date(this.entryDateFromStatus(b)); // Orden ascendente
				});
			} else {
				this.stopsToProcessed.sort((a, b) => {
					return new Date(this.entryDateFromStatus(b)) - new Date(this.entryDateFromStatus(a)); // Orden descendente
				});
			}
		},
		toggleMap() {
			this.visibleMap = !this.visibleMap;
		},
		// Flag para saber si se ha hecho click en un panel
		togglePanel(index) {
			this.isPanelClick = true;
		},
		togglePanels() {
			this.isBulkExpand = true;
			if (this.allPanelsOpen) {
				this.panel = this.visibleMap ? this.stopsProcessedByCreator.map((_, i) => i) : this.stopsToProcessed.map((_, i) => i);
			} else {
				this.panel = [];
			}
			this.isBulkExpand = false;
		},
		updateAllPanelsOpen(value) {
			this.allPanelsOpen = value;
			this.togglePanels();
		},
		masterProviderHeaders() {
			return this.modelConfiguration
				? this.modelConfiguration?.columns
						?.filter((column) =>
							['portcallnumber', 'vesselname', 'berthname', 'eta', 'etd', 'consigneename', 'vessellength'].includes(column.name)
						)
						.map((visibleColumn) => {
							return { text: this.$t(visibleColumn.title), value: visibleColumn.name, columntype: visibleColumn.type };
						})
				: [];
		},
		isEmpty(...values) {
			return values.some(
				(value) =>
					value === undefined ||
					value === null ||
					(typeof value === 'string' && value.trim() === '') ||
					(Array.isArray(value) && value.length === 0)
			);
		},
		updateStopsProcessedByCreator(stops) {
			this.stopsProcessedByCreator = stops;
		},
		resetElementToZoom() {
			this.elementToZoom = null;
		},
		handleMapManagerLoaded(mapManager) {
			this.mapManager = mapManager;
		},
		entryDateFromStatus(stop) {
			switch (stop.statusid) {
				case 'IN':
					return stop.ata;
				case 'PL':
					return stop.etaplan;
				case 'AU':
					return stop.etaauth;
				default:
					return stop.eta;
			}
		},
		updateTabExpanded(visibleFilters) {
			this.visibleFilters = visibleFilters;
		}
	}
};
</script>

<style>
@import '../../../styles/app-variables.pcss';
.rowContainer {
	padding-bottom: 0px;
	margin-bottom: -5px;
}
.tabContainer {
	height: 100%;
	display: flex;
	flex-direction: column;
}
.listContainer {
	border-radius: 1%;
	overflow: auto;
	height: calc(100vh - 230px);
}

.mapContainer {
	margin: 0px;
	padding: 0px;
	border-radius: 1%;
	height: calc(100vh - 230px);
}

.map-wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
}

gisweb-map {
	flex: 1 1 auto; /* Asegura que el mapa crezca y ocupe el espacio restante */
}

gisweb-ship-types-legend {
	flex-shrink: 0; /* No permite que el legend se reduzca */
}

.expansion {
	border-radius: 8px !important;
	padding-bottom: 0px !important;
	border: 1px solid var(--low) !important;
	margin-bottom: 10px;
}

.expansionactive {
	border-radius: 8px !important;
	background-color: #d7ebff !important;
	border: 1px solid var(--primary) !important;
}

.expandedTab {
	height: calc(100vh - 165px);
	margin-top: 35px;
}
</style>
