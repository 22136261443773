var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pui-form"},[_c('pelppproduct-modals',{attrs:{"modelName":_vm.modelName}}),(_vm.modelLoaded)?_c('v-form',{ref:"form",staticClass:"mb-4 pb-4",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"pui-form-layout mt-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('pui-text-field',{attrs:{"id":`description-pelppproduct`,"label":_vm.$t('pelppproduct.description'),"disabled":_vm.formDisabled,"required":"","toplabel":"","maxlength":"100"},model:{value:(_vm.model.description),callback:function ($$v) {_vm.$set(_vm.model, "description", $$v)},expression:"model.description"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[_c('pui-select',{attrs:{"id":"producttype","attach":"producttype","label":_vm.$t('pelppproduct.producttype'),"toplabel":"","disabled":_vm.formDisabled,"itemsToSelect":[{ producttype: _vm.model.producttype }],"modelFormMapping":{ producttype: 'producttype' },"items":[
								{ producttype: 'HYDROCARBURE', name: _vm.$t('pelppproduct.hydrocarbure') },
								{ producttype: 'CRUDE', name: _vm.$t('pelppproduct.crude') }
							],"itemValue":"producttype","itemText":"name","order":{ name: 'asc' },"reactive":"","required":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('pui-select',{attrs:{"id":"family","attach":"family","label":_vm.$t('pelppproduct.family'),"toplabel":"","disabled":_vm.formDisabled,"itemsToSelect":[{ family: _vm.model.family }],"modelFormMapping":{ family: 'family' },"items":[
								{ family: 'family1', name: _vm.$t('pelppproduct.family1') },
								{ family: 'family2', name: _vm.$t('pelppproduct.family2') },
								{ family: 'family3', name: _vm.$t('pelppproduct.family3') },
								{ family: 'Opera fuera de la terminal', name: _vm.$t('pelppproduct.outsideterminal') }
							],"itemValue":"family","itemText":"name","order":{ name: 'asc' },"reactive":"","required":""},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('pui-checkbox',{attrs:{"id":`heavy`,"label":_vm.$t('pelppproduct.heavy'),"disabled":_vm.formDisabled,"true-value":true,"false-value":false},model:{value:(_vm.model.heavy),callback:function ($$v) {_vm.$set(_vm.model, "heavy", $$v)},expression:"model.heavy"}})],1)],1)],1)],1),(!_vm.isModalDialog)?_c('pui-form-footer',[_c('pui-form-footer-btns',{attrs:{"formDisabled":_vm.formDisabled,"saveDisabled":_vm.saving,"save":_vm.save,"back":_vm.back}})],1):_vm._e()],1):_c('pui-form-loading')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }