import { Fill, Style, Stroke } from 'ol/style';
import Text from 'ol/style/Text';
import store from '@/store/store';

const polygonStyleFunction = function (feature, resolution) {
	return new Style({
		stroke: new Stroke({
			color: createStrokeStyle(feature),
			width: 0.75
		}),
		fill: createFillStyle(feature),
		text: feature.get('featureVisible') ? createTextStyle(feature, resolution) : null
	});
};
const getVesselText = function (feature, resolution) {
	let text = '';
	if (resolution < 1.5) {
		text = feature.get('vesselname');
	}
	return text;
};
const createTextStyle = function (feature, resolution) {
	const rotation = feature.get('alfa') > Math.PI / 2 && feature.get('alfa') < Math.PI ? feature.get('alfa') + Math.PI : feature.get('alfa');
	return new Text({
		text: getVesselText(feature, resolution),
		rotation,
		stroke: new Stroke({
			color: 'white',
			width: 4
		}),
		overflow: true,
		font: '8px sans-serif'
	});
};

const createStrokeStyle = function (feature) {
	const thematicColorPalete = store.getters.stopsTypeValues;
	if (!feature.get('featureVisible')) {
		return 'rgba(0, 0, 0, 0)';
	}
	return thematicColorPalete && feature.get('vesseltype') ? thematicColorPalete[feature.get('vesseltype')].coloroutline : '#000000'; // Color de relleno del círculo
};

const createFillStyle = function (feature) {
	const thematicColorPalete = store.getters.stopsTypeValues;
	if (!feature.get('featureVisible')) {
		return new Fill({ color: 'rgba(0, 0, 0, 0)' });
	}

	return new Fill({
		color: thematicColorPalete && feature.get('vesseltype') ? thematicColorPalete[feature.get('vesseltype')].color : '#cccccc'
	});
};

export default polygonStyleFunction;
